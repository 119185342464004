import {
  buildMapToField,
  handleQueryResolve
} from '../utils'

export default function (patientID) {
  if (patientID) {
    let attrs
    let where
    let query = ''
    if (Array.isArray(patientID)) {
      patientID.forEach((id, i) => {
        // anything after first query, should have prefix of UNION
        query += ` ${i > 0 ? 'UNION' : ''} SELECT ${id} AS PatientID, * FROM "DBA".p_rpt_dosset_condensed(${id}, null)`
      })
    } else {
      attrs = [{
        param: 'PatientID',
        type: 'sql.Int',
        value: patientID, // to support like
      }]

      where = ['PatientId = @PatientId']
      where.push('FillDate BETWEEN @fromDate')
      where.push('@toDate')

      query = `
        call "DBA".p_rpt_dosset_condensed(${patientID}, null);
      `
    }

    // if (rxNumber) {
    //     attrs.push({
    //         param: 'rxNumber',
    //         type: 'sql.VarChar(50)',
    //         value: '%' + rxNumber + '%' // to support like
    //     });

    //     where.push('RXNumber LIKE @rxNumber');
    // }

    // if (genericName) {
    //     attrs.push({
    //         param: 'genericName',
    //         type: 'sql.VarChar(50)',
    //         value: '%' + genericName + '%' // to support like
    //     });

    //     where.push('GenericName LIKE @genericName');
    // }

    // if (brandName) {
    //     attrs.push({
    //         param: 'brandName',
    //         type: 'sql.VarChar(50)',
    //         value: '%' + brandName + '%' // to support like
    //     });

    //     where.push('BrandName LIKE @brandName');
    // }

    return this.query(query).then(handleQueryResolve)
      .then(records => records.map(row => buildMapToField(row)))
  }
}
